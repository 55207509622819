import PropTypes from "prop-types"
import React from "react"


function TextTitle({ title, titleIcon, subtitle }) {
    return (
        <div className="w-full max-w-6xl flex flex-col gap-4">
            <h1 className="flex items-start justify-center gap-4">
                {
                    titleIcon ?
                        (
                            <>
                                <span>{titleIcon}</span>
                                <span className="text-left font-bold nexus-text uppercase select-none">{title}</span>
                            </>
                        ) : <span className="text-center font-bold nexus-text uppercase select-none">{title}</span>
                }
            </h1>
            {subtitle && <p className="font-normal nexus-text select-none">{subtitle}</p>}
        </div>
    )
}

TextTitle.defaultProps = {
    title: '',
    titleIcon: null,
    subtitle: ''
}

TextTitle.propTypes = {
	title: PropTypes.string.isRequired,
	titleIcon: PropTypes.element,
	subtitle: PropTypes.oneOfType([PropTypes.string, PropTypes.object, PropTypes.element]),
}

export default TextTitle
