// import { motion, useAnimation, useReducedMotion } from "framer-motion"
import React from "react"
// import { useInView } from "react-intersection-observer"

import { Breadcrumb } from '../components/Breadcrumb'


// const fadeUp = {
//   initial: { y: '20px', opacity: 0 },
//   animate: { y: 0, opacity: 1, transition: { duration: 0.5 } },
//   reducedMotion: { opacity: 1 }
// }

function PageTitlePanel({ backgroundImg, title, subtitle, relativeUrl, isMobile }) {

  // const prefersReducedMotion = useReducedMotion()
  // const control = useAnimation()
  // const [ref, inView] = useInView()

  // React.useEffect(() => {
  //   if (isMobile || prefersReducedMotion) control.start("reducedMotion")
  //   else if (inView) control.start("animate");
  // }, [control, inView, isMobile, prefersReducedMotion]);

  return (
    // <motion.div
    //   id='titlePanel'
    //   className="relative block w-full h-96 overflow-hidden"
    //   ref={ref}
    // >
    <div
      id='titlePanel'
      className="relative block w-full h-96 overflow-hidden"
    >
      {backgroundImg}
      {/* BG Overlay */}
      <div className="absolute inset-0 w-full h-full nexus-dark-bg bg-opacity-70 mix-blend-multiply"></div>
      {/* Text Container */}
      <div className="absolute inset-0 object-center">
        {/* <motion.div
          className="relative w-full h-full text-center uppercase flex flex-col justify-center items-center"
          variants={fadeUp}
          initial="initial"
          animate={control}
        > */}
        <div className="relative w-full h-full text-center uppercase flex flex-col justify-center items-center">
          <h1 className="px-4 py-2 lg:px-7 lg:py-5 w-auto nexus-neutral-bg bg-opacity-70 mix-blend-screen backdrop-blur-2xl nexus-light-text text-2xl lg:text-4xl leading-relaxed tracking-wider !font-black select-none">{title}</h1>
          {subtitle && <h2 className="px-4 py-2 lg:px-10 lg:py-3 w-auto nexus-neutral-bg bg-opacity-70 mix-blend-screen backdrop-blur-2xl nexus-light-text text-base leading-relaxed tracking-wider !font-bold select-none">{subtitle}</h2>}
        </div>
        {/* </motion.div> */}

        <Breadcrumb relativeUrl={relativeUrl} />

      </div>
    {/* </motion.div> */}
    </div>
  )
}

export default PageTitlePanel