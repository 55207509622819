import React from "react"

import Accordion from "../../lib/components/Accordion"
import ContactForm from "../../lib/components/ContactForm"
import Layout from "../../lib/components/Layout"
import PageTitlePanel from '../../lib/components/PageTitlePanel'
import VerticalTimeline from '../../lib/components/VerticalTimeline'
import Storage from '../../lib/storage'

function Page({ location }) {

  // React hook for checking if screen is mobile size
  const [width, setWidth] = React.useState(null);
  function handleWindowSizeChange() {
    setWidth(window.innerWidth);
  }
  React.useEffect(() => {
    if (typeof window !== "undefined") {
      window.addEventListener('resize', handleWindowSizeChange);
      return () => {
        window.removeEventListener('resize', handleWindowSizeChange);
      }
    }
    if (typeof window !== "undefined") {
      setWidth(window.innerWidth)
    }

  }, []);
  const isMobile = width <= 768;

  return (
    <Layout
      title={Storage.pages.serviceBasementWaterproofing.metadata.title}
      description={Storage.pages.serviceBasementWaterproofing.metadata.description}
      metadataScripts={Storage.pages.serviceBasementWaterproofing.metadata.scripts}
      subPages={[Storage.pages.services.metadata.title]}
      pageUrl={location.pathname}
      fixedButton="backToServices"
      bgNavSelector="titlePanel"
    >
      <PageTitlePanel
        title={Storage.pages.serviceBasementWaterproofing.metadata.title}
        subtitle={Storage.pages.serviceBasementWaterproofing.metadata.description}
        backgroundImg={Storage.pages.serviceBasementWaterproofing.titlePanel.backgroundImg}
        isMobile={isMobile}
        relativeUrl={location.pathname}
      />

      <VerticalTimeline
        title={Storage.pages.serviceBasementWaterproofing.problem.title}
        titleIcon={Storage.pages.serviceBasementWaterproofing.problem.titleIcon}
        subtitle={Storage.pages.serviceBasementWaterproofing.problem.subtitle}
        steps={Storage.pages.serviceBasementWaterproofing.problem.steps}
      />

      <VerticalTimeline
        title={Storage.pages.serviceBasementWaterproofing.solution.title}
        titleIcon={Storage.pages.serviceBasementWaterproofing.solution.titleIcon}
        steps={Storage.pages.serviceBasementWaterproofing.solution.recommendations}
        noLineConnector
      />

      <div className="relative w-full nexus-bg h-page-p v-page-p grid gap-8">
        <div className="grid gap-6 text-center">
          <p className="nexus-text uppercase">Ask about our qualifications to see how we can give you the best {Storage.pages.serviceBasementWaterproofing.metadata.title} in Melbourne.</p>
          <div className="flex justify-center">
            <div
              role='button'
              tabIndex={0}
              className="group block w-10 h-10 md:animate-bounce cursor-pointer"
              // variants={fadeDown}
              initial="initial"
              // animate={control}
              onClick={() => { if (typeof document !== 'undefined') document.getElementById('basementCta').scrollIntoView({ behavior: 'smooth', block: 'center' }) }}
              onKeyUp={() => null}
              onKeyDown={(e) => { e.preventDefault(); if (e.key === 'Enter') if (typeof document !== 'undefined') document.getElementById('basementCta').scrollIntoView({ behavior: 'smooth', block: 'center' }) }}
            >
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512">
                <path className="nexus-svg-inverted opacity-80 group-hover:opacity-100" d="M169.4 470.6c12.5 12.5 32.8 12.5 45.3 0l160-160c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L224 370.8 224 64c0-17.7-14.3-32-32-32s-32 14.3-32 32l0 306.7L54.6 265.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l160 160z" />
              </svg>
            </div>
          </div>
        </div>
      </div>

      <Accordion
        faqs={Storage.pages.serviceBasementWaterproofing.accordion}
      />

      {/* <Cta
        backgroundImg={Storage.pages.serviceBasementWaterproofing.cta.img}
        title={`Would you like to enquire further about ${Storage.pages.serviceBasementWaterproofing.metadata.title}?`}
        btn={<NexusButton title='Contact Us' link='/contact-us' linkType='internal' theme='dark' />}
        type='footer'
      /> */}

      <div id='basementCta' className="relative w-full h-page-p v-page-p nexus-bg">
        <div className="flex flex-col gap-4">
          <div className="grid gap-4">
            <h1 className="font-bold uppercase nexus-text">Enquiries Form</h1>
            <p className="font-normal nexus-text">Submit your enquiry below and we will reach out to you directly.</p>
          </div>
          <ContactForm />
        </div>
      </div>

    </Layout>
  )
}

export default Page
