import { useAnimation, useReducedMotion } from "framer-motion"
import React from "react"
import { useInView } from "react-intersection-observer"
import TextTitle from "./TextTitle"

function VerticalTimeline({ title, titleIcon, subtitle, steps, isMobile, noLineConnector }) {

  const prefersReducedMotion = useReducedMotion()
  const control = useAnimation()
  const [, inView] = useInView({ triggerOnce: true })

  React.useEffect(() => {
    if (isMobile || prefersReducedMotion) control.start("reducedMotion")
    else control.start("animate");
  }, [control, inView, isMobile, prefersReducedMotion]);

  // const fadeUp = {
  //   initial: { y: '20px', opacity: 0 },
  //   animate: { y: 0, opacity: 1, transition: { duration: 0.5 } },
  //   reducedMotion: { opacity: 1 }
  // }

  return (
    <div className="relative w-full h-full nexus-bg h-page-p v-page-p flex justify-center">
      <div className="w-full max-w-6xl flex flex-col justify-center items-center text-left gap-8">
        <TextTitle title={title} titleIcon={titleIcon} subtitle={subtitle} />
        <div className="w-full flex flex-col items-center">
          {
            steps.map((step, index) => {
              return (
                <div key={'step' + index} className="w-full flex justify-center gap-4">
                  <div className="flex flex-col items-center">
                    <div>
                      <div className="flex items-center justify-center w-10 h-10 border-2 border-neutral-500 dark:border-neutral-300 rounded-full">
                        {step.svg}
                      </div>
                    </div>
                    {(!noLineConnector && (index + 1 < steps.length)) && <div className="w-0.5 h-full bg-neutral-300"></div>}
                  </div>
                  <div className="pb-8 w-full max-w-4xl flex flex-col items-start gap-4">
                    <p className="text-xl font-bold nexus-text select-none">{step.title}</p>
                    <p className="nexus-text select-none">
                      {step.text}
                    </p>
                  </div>
                </div>
              )
            })
          }
        </div>
      </div>
    </div>
  )
}

export default VerticalTimeline