import React from "react"
import Storage from '../storage'

function Accordion({ faqs }) {

  const [, setItems] = React.useState([])
  const setSelected = (items, index) => {
    const _items = [...items]
    if (!_items[index].selected) {
      toggleSelected(_items, index)
    } else {
      resetSelected(_items)
    }
  }

  const toggleSelected = (_items, index) => {
    // If item not selected yet, then toggle
    _items.map((_item) => _item.selected = false)

    _items[index].selected = true

    setItems(_items)
    return null
  }

  const resetSelected = (_items) => {
    // If item already toggled, close all items
    _items.map((_item) => _item.selected = false)
    setItems(_items)
    return null
  }

  return (
    <div className="relative w-full nexus-bg h-page-p v-page-p">
      <div className="flex flex-col items-center text-left gap-8">
        <div tabIndex={-1} className="p-4 border-2 nexus-border border-opacity-90 group-hover:border-opacity-100 rounded-full lg:animate-grow pointer-events-none">
          {Storage.global.logoInverted}
        </div>
        {
          faqs.map((section) => {
            return (
              <React.Fragment key={section.sectionName}>
                <h1 className="font-bold text-2xl nexus-text uppercase select-none">{section.sectionName}</h1>
                {
                  section.faqs.map((item, index) => {
                    return (
                      <div key={section.sectionName + index} className="w-full flex flex-col items-center justify-center border nexus-faq-border">
                        <div role='button' key={item.q} tabIndex={0} className={`group p-2 w-full lg:max-w-3/4 flex justify-between items-center cursor-pointer nexus-text ${item.selected && 'nexus-accent-faq-bg transition-colors duration-300 ease-linear'}`} onClick={() => setSelected(section.faqs, index)} onKeyUp={() => null} onKeyDown={(e) => { if (e.key === 'Enter') setSelected(section.faqs, index) }}>
                          <div className="flex items-center">
                            {/* <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 text-blue-500" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth="2">
                      <path strokeLinecap="round" strokeLinejoin="round" d="M12 8v13m0-13V6a2 2 0 112 2h-2zm0 0V5.5A2.5 2.5 0 109.5 8H12zm-7 4h14M5 12a2 2 0 110-4h14a2 2 0 110 4M5 12v7a2 2 0 002 2h10a2 2 0 002-2v-7" />
                    </svg> */}
                            <div className={`${item.selected ? 'font-bold' : 'font-medium text-opacity-90 group-hover:text-opacity-100'} text-sm select-none ${item.selected ? 'nexus-inverted-text transition-colors duration-300 ease-linear' : 'nexus-text'}`}>{item.q}</div>
                          </div>
                          <svg className={`w-4 h-4 object-cover ${item.selected ? 'nexus-accent-faq-svg' : 'nexus-accent-faq-svg-inverted'} opacity-75 group-hover:opacity-100"`} aria-hidden="true" focusable="false" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512">
                            {!item.selected && <path d="M256 80c0-17.7-14.3-32-32-32s-32 14.3-32 32V224H48c-17.7 0-32 14.3-32 32s14.3 32 32 32H192V432c0 17.7 14.3 32 32 32s32-14.3 32-32V288H400c17.7 0 32-14.3 32-32s-14.3-32-32-32H256V80z" />}
                            {item.selected && <path d="M432 256c0 17.7-14.3 32-32 32L48 288c-17.7 0-32-14.3-32-32s14.3-32 32-32l352 0c17.7 0 32 14.3 32 32z" />}
                          </svg>
                          <span className="sr-only">Arrow Pointing ${item.selected ? 'Down' : 'Right'}}</span>
                        </div>
                        {item.selected && (<div className={`px-2 py-4 w-full text-left nexus-text select-none`}>{item.a}</div>)}
                      </div>
                    )
                  })
                }
              </React.Fragment>
            )
          }
          )
        }
      </div>
    </div >
  )
}

export default Accordion