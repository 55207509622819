import { Link } from "gatsby";
import React from "react";
import { getAllRelativeUrls } from "../services/manipulationSvc";
import Storage from '../storage';

export function Breadcrumb({ relativeUrl }) {
    // Return null if no relative URL is provided to component
    let urls;
    if (!relativeUrl) return null
    else urls = getAllRelativeUrls(relativeUrl)
    // console.log(urls)

    const allNavItems = [
        ...Storage.global.nav,
        // ...Storage.global.productGroups,
        // ...Storage.global.productGroups.map((i) => i.submenu).flat(),
        ...Storage.global.services,
        // ...Storage.global.locations,
        ...Storage.global.legal
    ]
    // console.log(allNavItems)

    return (
        <div className="hidden w-auto lg:w-3/4 sm:flex justify-center px-2 lg:h-page-p absolute sm:left-4 sm:bottom-4">
            <div className="inline-flex flex-1 items-center gap-3">
                {
                    urls.map((url, index) => {
                        const res = allNavItems.filter((i => i.link && url.includes(i.link))).map((i) => i.name)
                        {/* console.log(url, index)
                        console.log(res) */}
                        if (url === '/') {
                            return (
                                <React.Fragment key={index}>
                                    <Link to={url}>
                                        {
                                            <svg className="w-6 h-6 opacity-60 hover:opacity-100" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512">
                                                <path className="nexus-svg-light" d="M575.8 255.5c0 18-15 32.1-32 32.1h-32l.7 160.2c0 2.7-.2 5.4-.5 8.1V472c0 22.1-17.9 40-40 40H456c-1.1 0-2.2 0-3.3-.1c-1.4 .1-2.8 .1-4.2 .1H416 392c-22.1 0-40-17.9-40-40V448 384c0-17.7-14.3-32-32-32H256c-17.7 0-32 14.3-32 32v64 24c0 22.1-17.9 40-40 40H160 128.1c-1.5 0-3-.1-4.5-.2c-1.2 .1-2.4 .2-3.6 .2H104c-22.1 0-40-17.9-40-40V360c0-.9 0-1.9 .1-2.8V287.6H32c-18 0-32-14-32-32.1c0-9 3-17 10-24L266.4 8c7-7 15-8 22-8s15 2 21 7L564.8 231.5c8 7 12 15 11 24z" />
                                            </svg>
                                        }
                                    </Link>
                                    <div className="nexus-light-text">/</div>
                                </React.Fragment>
                            )
                        } else if (index + 1 < urls.length) {
                            return (
                                <React.Fragment key={index}>
                                    <Link to={url} className='flex-shrink truncate nexus-light-text text-xs lg:text-sm text-opacity-60 hover:text-opacity-100'>{res[res.length - 1]}</Link>
                                    <div className="nexus-light-text text-xs">/</div>
                                </React.Fragment>
                            )
                        } else {
                            return (
                                <p key={url + 'text'} className='flex nexus-light-text text-xs lg:text-sm'>{res[res.length - 1]}</p>
                            )
                        }
                    })
                }
            </div>
        </div>
    )
}
